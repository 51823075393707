// .lottie{
//   position: absolute;
//   top: 50%;
//   right: 50%;
//   transform: translate(50%, -50%);
//   width: 300px;
//   height: 300px;
//   z-index: 1;

//   &.lottie-2{
//     z-index: 2;
//   }
// }

@keyframes movingUp {
    from {
        top: 0px;
    }

    to {
        top: -14px;
    }
}

@-webkit-keyframes movingUp {
    from {
        top: 0px;
    }

    to {
        top: -14px;
    }
}

@keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@-webkit-keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}


@-webkit-keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

@keyframes draw-around {
    0% {
      stroke-dashoffset: 640;
    }
    10% {
      stroke-dashoffset: 640;
    }
    100% {
      stroke-dashoffset: 0;
    }
}
@-webkit-keyframes draw-around {
    0% {
      stroke-dashoffset: 640;
    }
    10% {
      stroke-dashoffset: 640;
    }
    100% {
      stroke-dashoffset: 0;
    }
}