@import 'animations';

@font-face {
	font-family: 'Open Sans';
	src: local('Open Sans'),
		url('../fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
	font-weight: 300 800;
	font-style: normal;
}

@font-face {
	font-family: 'Assistant';
	src: local('Assistant'),
		url('../fonts/Assistant-VariableFont_wght.ttf') format('truetype');
	font-weight: 200 800;
	font-style: normal;
}

@font-face {
	font-family: 'DeliciousHandrawn';
	src: local('Open Sans'),
		url('../fonts/DeliciousHandrawn-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

$primary: #f47440;
$primary_300: #fcd4c4;
$primary_200: #fdeae2;
$primary_100: #fef1ec;

$secondary: #3496d1;
$secondary_300: #c0def1;
$secondary_200: #e1eff8;
$secondary_100: #ebf5fa;

$comp: #33c4e8;
$comp_300: #c0edf8;
$comp_200: #d4f5fe;
$comp_100: #f0fcff;

$ntrl_100: #ffffff;
$ntrl_300: #dfe2e6;
$ntrl_800: #233b4b;
$ntrl_900: #091e42;

html {
	scroll-behavior: smooth;

	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}
	::-webkit-scrollbar-thumb {
		background: $comp;
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #2baccc;
	}
	::-webkit-scrollbar-track {
		background: #fcfcfc;
		border-radius: 0px;
		box-shadow: inset 1px 1px 3px 2px #c1c1c1;
	}

	.tbl,
	.custom-scroll {
		::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}

		::-webkit-scrollbar-thumb {
			background: $comp;
			border-radius: 10px;
		}
		::-webkit-scrollbar-thumb:hover {
			background: $secondary;
		}
		::-webkit-scrollbar-track {
			background: transparent;
			border-radius: 0px;
			box-shadow: unset;
		}
	}

	body {
		margin: 0;
		overflow-x: hidden;
		// font-family: 'Open Sans';
        font-family: 'Assistant';
		position: relative;
		//direction: rtl;

		*,
		*::after,
		*::before {
			transition: all ease-out 200ms;
			box-sizing: border-box;
		}

		a {
			text-decoration: none;
			color: inherit;
		}

		img {
			max-width: 100%;
			max-height: 100%;
		}

		//position
		.relative {
			position: relative;
		}

		.absolute {
			position: absolute;
		}

		.fixed {
			position: fixed;
		}

		//flex
		.flex {
			display: flex;
		}

		.column {
			flex-direction: column;
		}

		.align-center {
			align-items: center;
		}

		.align-start {
			align-items: flex-start;
		}

		.align-end {
			align-items: flex-end;
		}

		.align-base {
			align-items: baseline;
		}

		.justify-center {
			justify-content: center;
		}

		.justify-start {
			justify-content: flex-start;
		}

		.justify-end {
			justify-content: flex-end;
		}

		.justify-between {
			justify-content: space-between;
		}

		.wrap {
			flex-wrap: wrap;
		}

		.nowrap {
			flex-wrap: nowrap;
		}

		.row {
			flex-direction: row;
		}

		.row-reverse {
			flex-direction: row-reverse;
		}

		// .home .theguy_logo{
		// 	bottom: 11%;
			
		// 	@media only screen and (max-width: 768px) {
		// 		bottom: 2%;
		// 	}

		// 	svg {
		// 		path {
		// 			fill: #000;
		// 		}
		// 	}
		// }
		.theguy_logo {
			position: absolute;
			left: 2%;
			bottom: 2%;
			transform: scale(0.9);
			// transform: translateX(-50%);

			svg {
				path {
					fill: #000;
				}
			}

			&:hover {
				svg {
					path {
						fill: #000;
					}
				}
			}

				@media only screen and (max-width: 768px) {
					left: 50%;
					transform: translateX(-50%);
				}
		}

		.disabled-btn {
			pointer-events: none;
		}

		.loader {
			z-index: 8;
			position: relative;
			gap: 10px;

			span {
				position: relative;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				animation: movingUp 0.5s infinite alternate;
			}
		}

		.spinner {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			position: relative;
			animation: rotate 1s linear infinite;

			&::before {
				content: '';
				box-sizing: border-box;
				position: absolute;
				inset: 0px;
				border-radius: 50%;
				border: 3px solid $comp;
				animation: prixClipFix 2s linear infinite;
			}
		}

		.m-check-mark {
			opacity: 1;
			visibility: visible;
			width: 30px;

			.checkmark {
				-webkit-animation: draw-around 1s;
				animation: draw-around 1s;
			}
		}

		.stam {
			width: 75%;
			border-radius: 100px;
			background: #ebedf0;
			overflow: hidden;

			button {
				width: 50%;
				border: none;

				&:hover {
					background: unset;
				}
			}

			.checked-btn {
				background: #f47440;
				border-radius: 100px;
				color: #fff;
				border-bottom-left-radius: 100px !important;
				border-top-left-radius: 100px !important;
				border-bottom-right-radius: 100px !important;
				border-top-right-radius: 100px !important;

				&:hover {
					background: #f47440;
				}
			}
		}

		.quill {
			height: 250px;

			.ql-toolbar {
				background: $secondary_300;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				&.ql-snow .ql-formats {
					margin: 0 !important;
				}
			}

			.ql-container {
				height: 200px;
			}
		}

		.dropzone-ui {
			.dz-ui-header {
				display: none;
			}
			.file-status {
				display: none;
			}
		}

		.modal-admin-messages {
			.MuiAlert-message {
				width: 100%;
			}
		}
		p {
			a {
				text-decoration: underline;
			}
		}
		.arrows.animated {
			position: relative;
			-webkit-animation: movingUp 1s infinite alternate;
			animation: movingUp 1s infinite alternate;
		}

		.muirtl-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
			.MuiTableSortLabel-icon {
			color: $comp_100;

			// &:hover{
			//     color: $comp_100;
			// }
		}

		.vi__wrapper {
			width: 100%;
			direction: ltr;
			.vi__container {
				width: 100%;
				height: auto;
				gap: max(0.8vw, 12px);

				.vi__character {
					aspect-ratio: 1;
					width: max(3.8vw, 30px);
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 8px;
					border-color: rgba(0, 0, 0, 0.3);
					color: $ntrl_800;
				}

				.vi__character--selected {
					outline: 3px solid $comp;
					color: $comp;
					border-color: transparent;
				}

				.vi__character--inactive {
					background: $comp_100;
					color: $comp_100;
				}

				.vi__character:not(.vi__character--inactive) {
					background: $comp_200;
				}
			}
		}
	}
}
